import React from 'react';
import { createRoot } from 'react-dom/client';
import Calendar from './components/Calendar';

const container = document.getElementById('calendar-container');
if (container) {
  const events = JSON.parse(container.dataset.events);
  const root = createRoot(container);
  root.render(
    <Calendar events={events} />,
  );
}
