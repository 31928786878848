import React, { useState } from 'react';
import dayjs from 'dayjs';

require('dayjs/locale/pt-br');

const datesBetween = (startDate, stopDate) => {
  const dateArray = [];
  let currentDate = dayjs(startDate);
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate));
    currentDate = currentDate.add(1, 'day');
  }
  return dateArray;
};

function Calendar({ events }) {
  const [date, setDate] = useState(dayjs(new Date()));
  const firstDayOfCalendar = date.startOf('month').startOf('week').add(1, 'day');
  const lastDayOfCalendar = date.endOf('month').subtract(1, 'day').endOf('week').add(1, 'day');
  const dates = datesBetween(firstDayOfCalendar, lastDayOfCalendar);

  const previousVisibility = dayjs().isBefore(firstDayOfCalendar) ? 'visible' : 'hidden';

  const previousMonth = () => {
    setDate(date.startOf('month').subtract(1, 'day'));
  };

  const nextMonth = () => {
    setDate(date.endOf('month').add(1, 'day'));
  };

  const sameDate = (day1, day2) => day1.date() === day2.date() && day1.month() === day2.month();

  const dayClass = (day, event) => {
    const today = dayjs();
    if (event) {
      return 'enabled';
    } if (sameDate(day, today)) {
      return 'today';
    } if (day.isBefore(today)) {
      return 'disabled';
    } if (day.month() !== date.month()) {
      return 'bg-light';
    }
    return 'blank';
  };

  const toDay = (day) => {
    const event = events.find((item) => sameDate(day, dayjs(item.start_time)));
    const label = day.format('D').toString();
    return (
      <td className={dayClass(day, event)}>
        {event ? (
          <a href={`/t/${event.slug}`}>{label}</a>
        ) : label}
      </td>
    );
  };

  const html = [];
  const chunkSize = 7;
  for (let i = 0; i < dates.length; i += chunkSize) {
    const chunk = dates.slice(i, i + chunkSize);
    html.push((
      <tr>
        {chunk.map(toDay)}
      </tr>
    ));
  }

  return (
    <>
      <div className="d-flex w-100 text-center calendar-header">
        <div
          className="ml-auto"
          style={{ visibility: previousVisibility }}
        >
          <button
            className="btn flat month previous"
            title="Mês anterior"
            onClick={previousMonth}
            type="button"
          >
            {'<'}
          </button>
        </div>
        <strong className="text-center my-auto text-capitalize">
          {date.locale('pt-br').format('MMMM').toString()}
        </strong>
        <div className="mr-auto">
          <button
            className="btn flat month next"
            title="Próximo mês"
            onClick={nextMonth}
            type="button"
          >
            {'>'}
          </button>
        </div>
      </div>
      <table className="table table-bordered calendar mb-2">
        <thead>
          <tr>
            <th>SEG</th>
            <th>TER</th>
            <th>QUA</th>
            <th>QUI</th>
            <th>SEX</th>
            <th>SAB</th>
            <th>DOM</th>
          </tr>
        </thead>
        <tbody>
          {html}
        </tbody>
      </table>
      <div className="legend d-flex">
        <div className="mr-3">
          <div className="today mr-2">&nbsp;</div>
          <small className="text-muted">Dia atual</small>
        </div>
        <div>
          <div className="enabled mr-2">&nbsp;</div>
          <small className="text-muted">Dia com evento</small>
        </div>
      </div>
    </>
  );
}

export default Calendar;
